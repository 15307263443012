<template>
  <static-template>
    <h1 class="heading-size-3 mt-12 mb-12">{{ $t('anError') }}</h1>
    <div class="error-404">
      <div>
        <p>
          {{ $t('notFound404') }}
        </p>
      </div>
      <img src="@/assets/404/undraw_snap_the_moment_re_88cu.svg" alt="" />
    </div>
  </static-template>
</template>

<script>
export default {
  name: 'page-404',
  data: () => ({}),
};
</script>
